<template>
    <Modal :centered="true" :open="true" :footer="null" @cancel="close" :width="700">
        <template #title>
            <Space>
                <span>工单详情</span>
                <span>
                    <Tag color="#037ef3" v-if="info.state == 1">待审核</Tag>
                    <Tag color="#1cc7d0" v-else-if="info.state == 2">待解密</Tag>
                    <Tag color="#7552cc" v-else-if="info.state == 3">待返厂</Tag>
                    <Tag color="#f85a40" v-else-if="info.state == 4">审核失败</Tag>
                    <Tag color="#009f4d" v-else-if="info.state == 5">完成</Tag>
                </span>
            </Space>
        </template>
        <Descriptions :column="4" :bordered="true" size="small">
            <DescriptionsItem :span="2" label="上传微信">
                <TypographyText :copyable="true"> {{ getSmalluserName(info.smalluserid) }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="创建时间"> {{ dayjs(parseInt(info.createtime)).format("YYYY-MM-DD HH:mm:ss")
                }}
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="锁机时间">
                <span v-if="info.taketime">{{ dayjs(parseInt(info.taketime)).format("YYYY-MM-DD HH:mm:ss") }}</span>
                <span>-</span>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="设备SN">
                <TypographyText :copyable="true"> {{ info.csn }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="设备型号">{{ getCsntypeNmae(info.csntypeid) }}</DescriptionsItem>
            <DescriptionsItem :span="2" label="所属批次">{{ getproductionName(info.productionid) }}</DescriptionsItem>
            <DescriptionsItem :span="2" label="加密SN">
                <TypographyText :copyable="true"> {{ info.sesn }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="医保SN">
                <TypographyText :copyable="true" v-if="info.medicaresn"> {{ info.medicaresn }}</TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="系统版本">
                <TypographyText :copyable="true" v-if="info.devicerom"> {{ info.devicerom }}</TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="上传方式">
                <Tag color="#0099cc" v-if="info.types == 1">扫码</Tag>
                <Tag color="#ff9933" v-else-if="info.types == 2">手动</Tag>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="返厂">
                <Tag color="#ff4c4c" v-if="info.prostate == 1">需返厂</Tag>
                <Tag color="#34bf49" v-else-if="info.prostate == 2">不返厂</Tag>
                <Tag color="#caccd1" v-else>未知</Tag>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="错误编码">
                <TypographyText :copyable="true" v-if="info.sereason">{{ info.sereason }}</TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="锁机图片">
                <Button type="primary" @click="() => setVisible(true)">查看图片</Button>
                <Image :style="{ display: 'none' }" :preview="{
                    visible,
                    onVisibleChange: setVisible,
                }" :src="info.pictureurl"></Image>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="收件人电话">
                <TypographyText :copyable="true" v-if="info.addrphone"> {{ info.addrphone }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="2" label="收件人">
                <TypographyText :copyable="true" v-if="info.addrname"> {{ info.addrname }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="4" label="快递单号">
                <TypographyText :copyable="true" v-if="info.trackingnum"> {{ info.trackingnum }} </TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="4" label="返厂地址">
                <TypographyText :copyable="true" v-if="info.address"> {{ info.address }}</TypographyText>
            </DescriptionsItem>
            <DescriptionsItem :span="4" label="审核说明">{{ info.sereasonexp }}</DescriptionsItem>
        </Descriptions>
    </Modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Modal, Descriptions, Tag, TypographyText, Image, Button, Space } from "ant-design-vue";
import dayjs from 'dayjs';
export default {
    name: "opderInfo",
    components: { Modal, Descriptions, DescriptionsItem: Descriptions.Item, Tag, TypographyText, Image, Button, Space },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            dayjs,
        })
        const close = () => {
            emit("close");
        }
        const getSmalluserName = (id) => {
            const info = props.smalluserList.find(v => {
                return v.id == id;
            })
            if (info) {
                return info.nickname + info.phone;
            }
            return ""
        }
        const getCsntypeNmae = (id) => {
            const info = props.csntypeList.find(v => {
                return v.id == id;
            })
            if (info) {
                return info.name;
            }
            return ""
        }
        const getproductionName = (id) => {
            const info = props.productionList.find(v => {
                return v.id == id;
            })
            if (info) {
                return info.name;
            }
            return "未分类"
        }
        const setVisible = value => {
            state.visible = value;
        };
        return {
            ...toRefs(state), close, getSmalluserName, getCsntypeNmae, setVisible, getproductionName
        }
    },
    props: {
        info: Object,
        csntypeList: Array,
        smalluserList: Array,
        productionList: Object,
    }
}
</script>

<style lang="scss" scoped></style>