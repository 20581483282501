<template>
  <ConfigProvider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </ConfigProvider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

import { ConfigProvider } from "ant-design-vue";
export default {
  name: "App",
  components: { ConfigProvider },
  setup() {
    return {
      zhCN,
    };
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}
</style>
