<template>
    <Layout>
        <!-- 添加 -->
        <!-- 检索 -->
        <div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        模糊检索
                    </div>
                    </Col>
                    <Col :span="18">
                    <Input v-model:value="fuzzy" @change="change" allowClear placeholder="输入要检索的内容" />
                    </Col>
                </Row>
            </div>
        </div>
        <!-- 列表 -->
        <div>
            <Table :columns="columns" :data-source="dataSource" :rowKey="(record) => record.id" :pagination="false"
                :loading="loading" size="large">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        <span>
                            {{ index + (offset - 1) * limit + 1 }}
                        </span>
                    </template>
                    <template v-else-if="column.dataIndex === 'createtime'">
                        {{ dayjs(parseInt(record.createtime)).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'pictureurl'">
                        <Image :src="record.pictureurl"></Image>
                    </template>
                    <template v-else-if="column.dataIndex === 'types'">
                        <Tag v-if="record.types == 1" color="#f50">未绑定</Tag>
                        <Tag v-else-if="record.types == 2" color="#2db7f5">平台账号</Tag>
                        <Tag v-else color="#87d068">审核账号</Tag>
                    </template>
                    <template v-else-if="column.dataIndex === 'action'">
                        <Space v-if="auth[109]">
                            <Popconfirm title="是否确定把该小程序绑定到当前账户下?" @confirm="confirm(record, 1)">
                                <Button type="primary" :disabled="record.types == 1">绑定</Button>
                            </Popconfirm>
                            <Popconfirm title="是否确定解除该小程序绑定?" @confirm="confirm(record, 2)" v-if="record.types == 3">
                                <Button type="primary" danger :disabled="record.types == 1">解除</Button>
                            </Popconfirm>
                        </Space>
                    </template>
                </template>
            </Table>
            <Pagination :total="total" :showTotal="(total) => `一共 ${total} 条`" :pageSize="limit" @change="paginationChange"
                @showSizeChange="showSizeChange" v-model:current="offset" style="float: right" :showLessItems="true"
                :showSizeChanger="true" :showQuickJumper="true">
            </Pagination>
        </div>
    </Layout>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue'
import Layout from '@/views/menu/layout.vue'
import Que from "./que.js";
import { Pagination, Table, Row, Col, Input, Tag, Image, Space, Button, Popconfirm } from 'ant-design-vue';
import dayjs from 'dayjs';


export default {
    name: "cSn",
    components: { Layout, Table, Pagination, Row, Col, Input, Tag, Image, Space, Button, Popconfirm },
    setup() {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            dataSource: [],
            loading: false,
            total: 0,
            limit: 10,
            offset: 1,
            fuzzy: "",
            Que,
            dayjs,
            timer: null,
            addShow: false,
            auth: {},
        })
        onMounted(() => {
            state.auth = proxy.$req.userAuth()
            getData();
        });
        const getData = async () => {
            state.loading = true;
            var resp = await Que.get(proxy, { limit: state.limit, offset: (state.offset - 1) * state.limit, fuzzy: state.fuzzy })
            if (resp.code == 0) {
                state.dataSource = resp.data;
                state.total = parseInt(resp.count);
            }
            state.loading = false;
        };

        const columns = reactive([
            {
                title: "序号",
                dataIndex: 'index',
                width: 70,
            },
            {
                title: "昵称",
                dataIndex: 'nickname',
                ellipsis: true,
            },
            // {
            //     title: "头像",
            //     dataIndex: 'pictureurl',
            // },
            {
                title: "手机号",
                dataIndex: 'phone',
                ellipsis: true,
            },
            {
                title: "账号类型",
                dataIndex: 'types',
            },
            {
                title: "绑定账号",
                dataIndex: 'name',
                ellipsis: true,
            },
            {
                title: "创建时间",
                dataIndex: 'createtime',
                ellipsis: true,
            },
            {
                title: '操作',
                dataIndex: 'action',
            }
        ]);
        const paginationChange = () => {
            getData();
        };
        const childClose = () => {
            state.addShow = false;
            getData();
        }
        const change = () => {
            if (state.timer) {
                clearTimeout(state.timer);
                state.timer = null;
            }
            state.timer = setTimeout(() => {
                clearTimeout(state.timer);
                state.timer = null;
                childClose();
            }, 400)
        }
        const showSizeChange = (current, size) => {
            state.offset = 1;
            if (size) {
                state.limit = size;
            }
        };
        const confirm = async (value, states) => {
            state.loading = true;
            var resp = await Que.put(proxy, { id: value.id, state: states })
            if (resp.code == 0) {
                getData()
            }
            state.loading = false;
        };
        return {
            ...toRefs(state), columns, paginationChange, showSizeChange, change, childClose, confirm
        }
    }
}
</script>

<style lang="scss" scoped></style>