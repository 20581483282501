<template>
    <Modal :centered="true" v-model:open="open" title="工单审核" :footer="null" @cancel="close" :maskClosable="false"
        :width="550">
        <Spin :spinning="spinning">
            <Card>
                <Form :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="on"
                    @finish="onFinish">
                    <FormItem label="设备SN" name="csn"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.csn" :maxlength="30" :showCount="true" placeholder="请输入设备SN"
                            :disabled="formState.types == 1" />
                    </FormItem>
                    <FormItem label="芯片SN" name="sesn"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.sesn" :maxlength="30" :showCount="true" placeholder="请输入加密芯片SN"
                            :disabled="formState.types == 1" />
                    </FormItem>
                    <FormItem label="错误代码" name="sereason"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Select v-model:value="formState.sereason" placeholder="请选择错误代码">
                            <SelectOption value=""><span style="color: red;">未选择</span></SelectOption>
                            <SelectOption v-for=" v, k in sereasonList" :key="k" :value="v.name">{{ v.name + "--" +
                                v.details }}
                            </SelectOption>
                        </Select>
                    </FormItem>
                    <FormItem label="系统版本" name="devicerom"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Select v-model:value="formState.devicerom" :disabled="formState.types == 1">
                            <SelectOption value=""><span style="color: red;">未选择</span></SelectOption>
                            <SelectOption v-for=" v, k in sysVersionList" :key="k" :value="v.name">{{ v.name + "--" +
                                v.details }}
                            </SelectOption>
                        </Select>
                    </FormItem>
                    <FormItem label="是否返厂" name="prostate"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Select v-model:value="formState.prostate" placeholder="请选择错误代码">
                            <SelectOption :value="0"><span style="color: red;">未选择</span></SelectOption>
                            <SelectOption :value="1"><span style="color: #49a942;">返厂</span></SelectOption>
                            <SelectOption :value="2"><span style="color: #002663;">不返厂</span></SelectOption>
                        </Select>
                    </FormItem>
                    <FormItem v-if="formState.prostate == 1" label="返厂地址" name="addressid"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Select v-model:value="formState.addressid">
                            <SelectOption :value="0"><span style="color: red;">未选择</span></SelectOption>
                            <SelectOption v-for=" v, k in addressList" :key="k" :value="v.id">
                                <Tooltip>
                                    <template #title> {{ v.name + "-" + v.phone + "-" + v.details }}</template>
                                    {{ v.name + "-" + v.phone + "-" + v.details }}
                                </Tooltip>
                            </SelectOption>
                        </Select>
                    </FormItem>
                    <FormItem label="审核结果" name="state"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <RadioGroup v-model:value="formState.state" button-style="solid">
                            <RadioButton :value="1" style="width: 188px;">审核成功</RadioButton>
                            <RadioButton :value="2" style="width: 188px;">审核失败</RadioButton>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="审核说明" name="explain"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <InputTextArea v-model:value="formState.explain" :maxlength="30" :showCount="true"
                            placeholder="请输入审核说明" />
                    </FormItem>
                    <FormItem :wrapperCol="{ offset: 0, span: 24 }">
                        <Button type="primary" html-type="submit" style="width:100%">确定</Button>
                        <Button type="primary" danger @click="close" style="width:100%; margin-top: 10px;">取消</Button>
                    </FormItem>
                </Form>
            </Card>
        </Spin>
    </Modal>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { Modal, Spin, Form, Input, Button, Card, Select, Tooltip, RadioGroup, RadioButton } from "ant-design-vue";
import Que from "./que.js";
import sysversionQue from "../sysversion/que"
import sereasonQue from "../sereason/que"
import addressQue from "../address/que"

export default {
    name: "orderUpdate",
    components: { Modal, Spin, Form, FormItem: Form.Item, Input, Button, Card, Select, SelectOption: Select.Option, InputTextArea: Input.TextArea, Tooltip, RadioGroup, RadioButton },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            open: true,
            spinning: false,
            Que,
            sysversionQue,
            sereasonQue,
            addressQue,
            sysVersionList: [],
            sereasonList: [],
            addressList: [],
        })
        onMounted(() => {
            sysVersionList();
            sereasonList();
            sysAddressList();
            formState.csn = props.info.csn;
            formState.sesn = props.info.sesn;
            formState.sereason = props.info.sereason;
            formState.devicerom = props.info.devicerom;
            formState.types = props.info.types;
            formState.prostate = props.info.prostate;
        })
        const sysAddressList = async () => {
            var resp = await addressQue.get(proxy, { limit: 999 })
            if (resp.code != 0) {
                return
            }
            state.addressList = resp.data;
        }
        const sysVersionList = async () => {
            var resp = await sysversionQue.get(proxy, { limit: 999 })
            if (resp.code != 0) {
                return
            }
            state.sysVersionList = resp.data;
        }
        const sereasonList = async () => {
            var resp = await sereasonQue.get(proxy, { limit: 999 })
            if (resp.code != 0) {
                return
            }
            state.sereasonList = resp.data;
        }
        const close = (result) => {
            emit("close", result)
        }
        const formState = reactive({
            state: 1,//审核结果 1审核成功 2审核失败
            explain: "",//审核说明
            prostate: 0, //是否返厂 1是 2否
            addressid: 0, //返厂地址
            csn: "",//设备SN
            sesn: "",//加密芯片SN
            sereason: "", //错误代码
            devicerom: "", // 系统版本
            types: 0,//上传方式 1二维码 2人工
        });
        const validatePass = (rule, value) => {
            switch (rule.field) {
                case "explain":
                    if (value == "") {
                        return Promise.reject("审核说明不能为空")
                    }
                    break;
                case "prostate":
                    if (value == 0 && formState.state == 1) {
                        return Promise.reject("是否返厂不能为空")
                    }
                    break;
                case "addressid":
                    if (value == 0 && formState.state == 1) {
                        return Promise.reject("返厂地址不能为空")
                    }
                    break;
                case "devicerom":
                    if (value == "" && formState.state == 1) {
                        return Promise.reject("系统版本不能为空")
                    }
                    break;
                case "csn":
                    if (value == "" && formState.state == 1) {
                        return Promise.reject("设备SN不能为空")
                    }
                    break;
                case "sesn":
                    if (value.length != 16 && formState.state == 1) {
                        return Promise.reject("加密芯片SN长度为16位")
                    }
                    break;
                case "sereason":
                    if (value == "" && formState.state == 1) {
                        return Promise.reject("错误代码不能为空")
                    }
                    break;
                default:
                    break;
            }
            return Promise.resolve()
        };
        const onFinish = async (value) => {
            state.spinning = true;
            value.id = props.info.id;
            value.types = props.info.types;
            var result = await Que.put(proxy, value)
            state.spinning = false;
            if (result) {
                close(true)
            }
        };
        return {
            ...toRefs(state), close, onFinish, formState, validatePass
        }
    },
    props: {
        info: Object,
    }
}
</script>

<style lang="scss" scoped></style>