
import address from '@/components/address'
import csn from '@/components/csn'
import device from '@/components/device'
import order from '@/components/order'
import production from '@/components/production'
import sereason from '@/components/sereason'
import sesn from '@/components/sesn'
import sysversion from '@/components/sysversion'
import webchatUser from '@/components/webchatUser'
import {createRouter, createWebHashHistory} from 'vue-router'

import indexView from '../views/indexView.vue'
import updatePassword from '../views/updatePassword.vue'

const routes = [
  {
    path: '/order',
    name: 'order',
    component: order,
    details: '工单列表',
    key: 'order'
  },
  {
    path: '/',
    name: 'index',
    component: indexView,
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: updatePassword,
  },
  {path: '/csn', name: 'csn', component: csn, details: '设备型号', key: 'csn'},
  {
    path: '/production',
    name: 'production',
    component: production,
    details: '生产批次',
    key: 'production'
  },
  {
    path: '/device',
    name: 'device',
    component: device,
    details: '设备列表',
    key: 'device'
  },
  {
    path: '/sereason',
    name: 'sereason',
    component: sereason,
    details: '错误编码',
    key: 'sereason'
  },
  {
    path: '/sysversion',
    name: 'sysversion',
    component: sysversion,
    details: '系统版本',
    key: 'sysversion'
  },
  {
    path: '/address',
    name: 'address',
    component: address,
    details: '返厂地址',
    key: 'address'
  },
  {
    path: '/sesn',
    name: 'sesn',
    component: sesn,
    details: '加密芯片',
    key: 'sesn'
  },
  {
    path: '/webchatUser',
    name: 'webchatUser',
    component: webchatUser,
    details: '用户列表',
    key: 'webchatUser'
  },
];

const router =
    createRouter({history: createWebHashHistory(process.env.BASE_URL), routes})

export default router
