<template>
    <Layout>
        <!-- 检索 -->
        <div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        生产批次
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="productionid" style="width: 100%" @change="change">
                        <SelectOption :value="-1">全部</SelectOption>
                        <SelectOption :value="0">未分类</SelectOption>
                        <SelectOption v-for="item in productionList" :key="item.id">
                            {{ item.name }}
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        微信用户
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="smalluserid" style="width: 100%" @change="change">
                        <SelectOption :value="0">全部</SelectOption>
                        <SelectOption v-for="item in smalluserList" :key="item.id">
                            {{ item.nickname }}{{ item.phone }}
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        设备型号
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="csntypeid" style="width: 100%" @change="change">
                        <SelectOption :value="0">全部</SelectOption>
                        <SelectOption :value="-1"><span style="color: red;">型号未知</span> </SelectOption>
                        <SelectOption v-for="item in csntypeList" :key="item.id">
                            {{ item.name }}
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        审核状态
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="state" style="width: 100%" @change="change">
                        <SelectOption :value="0">全部</SelectOption>
                        <SelectOption :value="1">
                            <Tag color="#037ef3">待审核</Tag>
                        </SelectOption>
                        <SelectOption :value="2">
                            <Tag color="#1cc7d0">待解密</Tag>
                        </SelectOption>
                        <SelectOption :value="3">
                            <Tag color="#7552cc">待返厂</Tag>
                        </SelectOption>
                        <SelectOption :value="4">
                            <Tag color="#f85a40">审核失败</Tag>
                        </SelectOption>
                        <SelectOption :value="5">
                            <Tag color="#009f4d">完成</Tag>
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        上传方式
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="types" style="width: 100%" @change="change">
                        <SelectOption :value="0">
                            全部
                        </SelectOption>
                        <SelectOption :value="1">
                            扫码
                        </SelectOption>
                        <SelectOption :value="2">
                            人工
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        返厂方式
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="prostate" style="width: 100%" @change="change">
                        <SelectOption :value="0">
                            全部
                        </SelectOption>
                        <SelectOption :value="1">
                            返厂
                        </SelectOption>
                        <SelectOption :value="2">
                            不返厂
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        模糊检索
                    </div>
                    </Col>
                    <Col :span="18">
                    <Input v-model:value="fuzzy" @change="change" allowClear placeholder="输入要检索的内容" />
                    </Col>
                </Row>
            </div>

            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="4">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        时间
                    </div>
                    </Col>
                    <Col :span="20">
                    <RangePicker v-model:value="allTime" show-time style="width: 100%;" :allowClear="false"
                        @change=panelChange>
                    </RangePicker>
                    </Col>
                </Row>
            </div>
        </div>
        <!-- 列表 -->
        <div>
            <Table :columns="columns" :data-source="dataSource" :rowKey="(record) => record.id" :pagination="false"
                :loading="loading" size="large">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        <span>
                            {{ index + (offset - 1) * limit + 1 }}
                        </span>
                    </template>
                    <template v-else-if="column.dataIndex === 'createtime'">
                        {{ dayjs(parseInt(record.createtime)).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'csntypeid'">
                        {{ getCsntypeNmae(record.csntypeid) }}
                    </template>
                    <template v-else-if="column.dataIndex === 'state'">
                        <Tag color="#037ef3" v-if="record.state == 1">待审核</Tag>
                        <Tag color="#1cc7d0" v-else-if="record.state == 2">待解密</Tag>
                        <Tag color="#7552cc" v-else-if="record.state == 3">待返厂</Tag>
                        <Tag color="#f85a40" v-else-if="record.state == 4">审核失败</Tag>
                        <Tag color="#009f4d" v-else-if="record.state == 5">完成</Tag>
                    </template>
                    <template v-else-if="column.dataIndex === 'prostate'">
                        <Tag color="#ff4c4c" v-if="record.prostate == 1">需返厂</Tag>
                        <Tag color="#34bf49" v-else-if="record.prostate == 2">不返厂</Tag>
                        <Tag color="#caccd1" v-else>未知</Tag>
                    </template>
                    <template v-else-if="column.dataIndex === 'action'">
                        <Space>
                            <Button type="dashed" @click="info = record">详情</Button>
                            <Button type="primary" @click="update = record"
                                v-if="auth[106] && record.state == 1">审核</Button>
                            <Popconfirm title="是否确认设备已经收到?" @confirm="confirm(record)">
                                <Button type="primary" v-if="auth[106] && record.state == 3">收货</Button>
                            </Popconfirm>
                        </Space>
                    </template>

                </template>
            </Table>
            <Pagination :total="total" :showTotal="(total) => `一共 ${total} 条`" :pageSize="limit"
                @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
                style="float: right" :showLessItems="true" :showSizeChanger="true" :showQuickJumper="true">

            </Pagination>
        </div>
        <Info v-if="info != null" @close="childClose" :info="info" :smalluserList="smalluserList"
            :csntypeList="csntypeList" :productionList="productionList">
        </Info>
        <Update v-if="update != null" @close="childClose" :info="update" :smalluserList="smalluserList"
            :csntypeList="csntypeList" />
    </Layout>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue'
import Layout from '@/views/menu/layout.vue'
import Que from "./que.js";
import { Pagination, Table, Row, Col, Input, Space, Button, Select, Tag, Popconfirm, RangePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import csnQue from "../csn/que"
import webchatQue from "../webchatUser/que"
import Info from "./info.vue"
import Update from "./update.vue"
import productionQue from "../production/que"

export default {
    name: "orderIndex",
    components: { Layout, Table, Pagination, Row, Col, Input, Space, Button, Select, SelectOption: Select.Option, Tag, Info, Update, Popconfirm, RangePicker },
    setup() {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            dataSource: [],
            loading: false,
            total: 0,
            limit: 10,
            offset: 1,
            fuzzy: "",
            state: 0,
            Que,
            dayjs,
            timer: null,
            addShow: false,
            auth: {},
            csnQue,
            webchatQue,
            csntypeList: [],
            csntypeid: 0,
            smalluserid: 0,
            smalluserList: [],
            info: null,
            update: null,
            productionList: [],
            productionid: -1,
            productionQue,
            types: 0,
            prostate: 0,
            starttime: 0,
            endtime: 0,
            allTime: [],
        })
        onMounted(() => {
            state.auth = proxy.$req.userAuth()
            getCsnTypeList();
            getSmalluserList();
            getData();
            getProList();
        });
        const getData = async () => {
            state.loading = true;
            var resp = await Que.get(proxy, { limit: state.limit, offset: (state.offset - 1) * state.limit, fuzzy: state.fuzzy, smalluserid: state.smalluserid, csntypeid: state.csntypeid, state: state.state, productionid: state.productionid, types: state.types, prostate: state.prostate, starttime: state.starttime, endtime: state.endtime })
            if (resp.code == 0) {
                state.dataSource = resp.data;
                state.total = parseInt(resp.count);
            }
            state.loading = false;
        };
        const columns = reactive([
            {
                title: "序号",
                dataIndex: 'index',
                width: 70,
            },
            {
                title: "设备SN",
                dataIndex: 'csn',
            },
            {
                title: "设备型号",
                dataIndex: 'csntypeid',
                ellipsis: true,
            },
            {
                title: "状态",
                dataIndex: 'state',
            },
            {
                title: "是否返厂",
                dataIndex: 'prostate',
                ellipsis: true,
            },
            {
                title: "错误说明",
                dataIndex: 'sereasonexp',
                ellipsis: true,
            },
            {
                title: "创建时间",
                dataIndex: 'createtime',
                ellipsis: true,
            },
            {
                title: '操作',
                dataIndex: 'action',
            }
        ]);
        const paginationChange = () => {
            getData();
        };
        const getProList = async () => {
            var resp = await productionQue.get(proxy, { limit: 999 })
            if (resp.code == 0) {
                state.productionList = resp.data;
            }
        }
        const childClose = (value) => {
            state.info = null;
            state.addShow = false;
            state.update = null;
            if (value) {
                getData();
            }
        }
        const change = () => {
            if (state.timer) {
                clearTimeout(state.timer);
                state.timer = null;
            }
            state.timer = setTimeout(() => {
                clearTimeout(state.timer);
                state.timer = null;
                getData();
            }, 400)
        }
        const showSizeChange = (current, size) => {
            state.offset = 1;
            if (size) {
                state.limit = size;
            }
        };
        const getCsnTypeList = async () => {
            var resp = await csnQue.get(proxy, { limit: 999 })
            if (resp.code == 0) {
                state.csntypeList = resp.data;
            }
        };
        const getSmalluserList = async () => {
            var resp = await webchatQue.get(proxy, { limit: 999 })
            if (resp.code == 0) {
                state.smalluserList = resp.data;
            }
        }
        const getCsntypeNmae = (id) => {
            const info = state.csntypeList.find(v => {
                return v.id == id;
            })
            if (info) {
                return info.name;
            }
            return ""
        }
        const confirm = async (value) => {
            state.loading = true;
            await Que.put(proxy, { id: value.id, state: 3 })
            state.loading = true;
            getData()
        }
        const panelChange = (value,) => {
            state.starttime = value[0].valueOf()
            state.endtime = value[1].valueOf();
            change()
        }
        return {
            ...toRefs(state), columns, paginationChange, showSizeChange, change, childClose, getCsntypeNmae, confirm, panelChange
        }
    }
}
</script>

<style lang="scss" scoped></style>