<template>
    <Layout>
        <!-- 添加 -->
        <div style="text-align: right; padding: 10px;">
            <Space>
                <Button type="primary" @click="addShow = true" :disabled="!auth[101]">新增</Button>
            </Space>
        </div>
        <!-- 检索 -->
        <div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        模糊检索
                    </div>
                    </Col>
                    <Col :span="18">
                    <Input v-model:value="fuzzy" @change="change" allowClear placeholder="输入要检索的内容" />
                    </Col>
                </Row>
            </div>
        </div>
        <!-- 列表 -->
        <div>
            <Table :columns="columns" :data-source="dataSource" :rowKey="(record) => record.id" :pagination="false"
                :loading="loading" size="large">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        <span>
                            {{ index + (offset - 1) * limit + 1 }}
                        </span>
                    </template>
                    <template v-else-if="column.dataIndex === 'createtime'">
                        {{ dayjs(parseInt(record.createtime)).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'protime'">
                        {{ dayjs(parseInt(record.protime)).format("YYYY-MM-DD") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'state'">
                        <Tag v-if="record.state == 1" color="#2db7f5">返厂</Tag>
                        <Tag v-else color="#87d068">不返厂</Tag>
                    </template>
                    <template v-else-if="column.dataIndex === 'action'">
                        <Space>
                            <Button type="primary" @click="updateInfo = record" :disabled="!auth[102]">修改</Button>
                            <Popconfirm @confirm="confirm(record)" title="是否确认删除改生产批次?">
                                <Button type="primary" danger>删除</Button>
                            </Popconfirm>
                        </Space>
                    </template>
                </template>
            </Table>
            <Pagination :total="total" :showTotal="(total) => `一共 ${total} 条`" :pageSize="limit" @change="paginationChange"
                @showSizeChange="showSizeChange" v-model:current="offset" style="float: right" :showLessItems="true"
                :showSizeChanger="true" :showQuickJumper="true">

            </Pagination>
        </div>
        <Add v-if="addShow" @close="childClose" />
        <Update v-if="updateInfo != null" @close="childClose" :info="updateInfo" />
    </Layout>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue'
import Layout from '@/views/menu/layout.vue'
import Que from "./que.js";
import { Pagination, Table, Row, Col, Input, Space, Button, Tag, Popconfirm } from 'ant-design-vue';
import dayjs from 'dayjs';
import Add from './add.vue';
import Update from './update.vue';

export default {
    name: "productionIndex",
    components: { Layout, Table, Pagination, Row, Col, Input, Space, Button, Add, Tag, Popconfirm, Update },
    setup() {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            dataSource: [],
            loading: false,
            total: 0,
            limit: 10,
            offset: 1,
            fuzzy: "",
            Que,
            dayjs,
            timer: null,
            addShow: false,
            auth: {},
            updateInfo: null,
        })
        onMounted(() => {
            state.auth = proxy.$req.userAuth()
            getData();
        });
        const getData = async () => {
            state.loading = true;
            var resp = await Que.get(proxy, { limit: state.limit, offset: (state.offset - 1) * state.limit, fuzzy: state.fuzzy })
            if (resp.code == 0) {
                state.dataSource = resp.data;
                state.total = parseInt(resp.count);
            }
            state.loading = false;
        };

        const columns = reactive([
            {
                title: "序号",
                dataIndex: 'index',
                width: 70,
            },
            {
                title: "名称",
                dataIndex: 'name',
                ellipsis: true,
            },
            {
                title: "数量",
                dataIndex: 'total',
            },
            {
                title: "状态",
                dataIndex: 'state',
                ellipsis: true,
            },
            {
                title: "生产时间",
                dataIndex: 'protime',
                ellipsis: true,
            },
            {
                title: "备注",
                dataIndex: 'details',
                ellipsis: true,
            },
            {
                title: "创建时间",
                dataIndex: 'createtime',
                ellipsis: true,
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 160,
            }
        ]);
        const paginationChange = () => {
            getData();
        };
        const childClose = () => {
            state.addShow = false;
            state.updateInfo = null;
            getData();
        }
        const change = () => {
            if (state.timer) {
                clearTimeout(state.timer);
                state.timer = null;
            }
            state.timer = setTimeout(() => {
                clearTimeout(state.timer);
                state.timer = null;
                childClose();
            }, 400)
        }
        const showSizeChange = (current, size) => {
            state.offset = 1;
            if (size) {
                state.limit = size;
            }
        };
        const confirm = async (value) => {
            state.loading = true;
            await Que.del(proxy, value.id)
            state.loading = false;
            getData();
        }
        return {
            ...toRefs(state), columns, paginationChange, showSizeChange, change, childClose, confirm
        }
    }
}
</script>

<style lang="scss" scoped></style>