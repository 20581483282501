<template>
    <Layout>
        <!-- 检索 -->
        <div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        生产批次
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="productionid" style="width: 100%" @change="change">
                        <SelectOption :value="0">全部</SelectOption>
                        <SelectOption v-for="item in productionList" :key="item.id">
                            {{ item.name }}
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        设备型号
                    </div>
                    </Col>
                    <Col :span="18">
                    <Select v-model:value="csntypeid" style="width: 100%" @change="change">
                        <SelectOption :value="0">全部</SelectOption>
                        <SelectOption v-for="item in csntypeList" :key="item.id">
                            {{ item.name }}
                        </SelectOption>
                    </Select>
                    </Col>
                </Row>
            </div>
            <div
                style="width: 350px; background: rgb(228, 226, 224); padding: 5px; border-radius: 10px; margin: 5px; float: left;">
                <Row>
                    <Col :span="6">
                    <div style="text-align: right; padding-right: 10px; padding-top: 10px; font-weight: bolder; ">
                        模糊检索
                    </div>
                    </Col>
                    <Col :span="18">
                    <Input v-model:value="fuzzy" @change="change" allowClear placeholder="输入要检索的内容" />
                    </Col>
                </Row>
            </div>
        </div>
        <!-- 列表 -->
        <div>
            <Table :columns="columns" :data-source="dataSource" :rowKey="(record) => record.id" :pagination="false"
                :loading="loading" size="large">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        <span>
                            {{ index + (offset - 1) * limit + 1 }}
                        </span>
                    </template>
                    <template v-else-if="column.dataIndex === 'createtime'">
                        {{ dayjs(parseInt(record.createtime)).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'protime'">
                        {{ dayjs(parseInt(record.protime)).format("YYYY-MM-DD") }}
                    </template>
                    <template v-else-if="column.dataIndex === 'state'">
                        <Tag v-if="record.state == 1" color="#2db7f5">返厂</Tag>
                        <Tag v-else color="#87d068">不返厂</Tag>
                    </template>
                    <template v-else-if="column.dataIndex === 'csntypeid'">
                        {{ getCsnTypeName(record) }}
                    </template>
                    <template v-else-if="column.dataIndex === 'action'">
                        <Space>
                            <Button type="primary" @click="updateInfo = record" :disabled="!auth[108]">修改</Button>
                        </Space>
                    </template>
                </template>
            </Table>
            <Pagination :total="total" :showTotal="(total) => `一共 ${total} 条`" :pageSize="limit" @change="paginationChange"
                @showSizeChange="showSizeChange" v-model:current="offset" style="float: right" :showLessItems="true"
                :showSizeChanger="true" :showQuickJumper="true">

            </Pagination>
        </div>
        <Update v-if="updateInfo != null" @close="childClose" :info="updateInfo" />
    </Layout>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue'
import Layout from '@/views/menu/layout.vue'
import Que from "./que.js";
import { Pagination, Table, Row, Col, Input, Space, Button, Tag, Select } from 'ant-design-vue';
import dayjs from 'dayjs';
import Update from './update.vue';
import productionQue from "../production/que"
import csnQue from "../csn/que"

export default {
    name: "deviceIndex",
    components: { Layout, Table, Pagination, Row, Col, Input, Space, Button, Tag, Update, Select, SelectOption: Select.Option },
    setup() {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            dataSource: [],
            loading: false,
            total: 0,
            limit: 10,
            offset: 1,
            fuzzy: "",
            Que,
            productionQue,
            csnQue,
            dayjs,
            timer: null,
            addShow: false,
            auth: {},
            updateInfo: null,
            productionList: [],
            productionid: 0,
            csntypeList: [],
            csntypeid: 0,
        })
        onMounted(() => {
            state.auth = proxy.$req.userAuth()
            getProList();
            getCsnTypeList();
            getData();
        });
        const getData = async () => {
            state.loading = true;
            var resp = await Que.get(proxy, { limit: state.limit, offset: (state.offset - 1) * state.limit, fuzzy: state.fuzzy, productionid: state.productionid })
            if (resp.code == 0) {
                state.dataSource = resp.data;
                state.total = parseInt(resp.count);
            }
            state.loading = false;
        };

        const columns = reactive([
            {
                title: "序号",
                dataIndex: 'index',
                width: 70,
            },
            {
                title: "设备SN",
                dataIndex: 'csn',
                ellipsis: true,
            },
            {
                title: "设备型号",
                dataIndex: 'csntypeid',
                ellipsis: true,
            },
            {
                title: "医保SN",
                dataIndex: 'medicaresn',
                ellipsis: true,
            },
            {
                title: "加密芯片",
                dataIndex: 'sesn',
                ellipsis: true,
            },
            {
                title: "状态",
                dataIndex: 'state',
                ellipsis: true,
            },
            {
                title: "生产时间",
                dataIndex: 'protime',
                ellipsis: true,
            },
            {
                title: "创建时间",
                dataIndex: 'createtime',
                ellipsis: true,
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 90,
            }
        ]);
        const paginationChange = () => {
            getData();
        };
        const childClose = () => {
            state.addShow = false;
            state.updateInfo = null;
            getData();
        }
        const change = () => {
            if (state.timer) {
                clearTimeout(state.timer);
                state.timer = null;
            }
            state.timer = setTimeout(() => {
                clearTimeout(state.timer);
                state.timer = null;
                childClose();
            }, 400)
        }
        const showSizeChange = (current, size) => {
            state.offset = 1;
            if (size) {
                state.limit = size;
            }
        };
        const confirm = async (value) => {
            state.loading = true;
            await Que.del(proxy, value.id)
            state.loading = false;
            getData();
        }
        const getProList = async () => {
            var resp = await productionQue.get(proxy, { limit: 999 })
            if (resp.code == 0) {
                state.productionList = resp.data;
            }
        }
        const getCsnTypeList = async () => {
            var resp = await csnQue.get(proxy, { limit: 999 })
            if (resp.code == 0) {
                state.csntypeList = resp.data;
            }
        }
        const getCsnTypeName = (record) => {
            const info = state.csntypeList.find(v => {
                return v.id == record.csntypeid;
            })
            if (info) {
                return info.name;
            }
            return ""
        }
        return {
            ...toRefs(state), columns, paginationChange, showSizeChange, change, childClose, confirm, getProList, getCsnTypeName
        }
    }
}
</script>

<style lang="scss" scoped></style>