<template>
    <Modal :centered="true" :open="true" title="新增设备生产批次" :footer="null" @cancel="close" :maskClosable="false">
        <Spin :spinning="spinning" :indicator="null">
            <Card>
                <Form :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="on"
                    @finish="onFinish" ref="formRef">

                    <FormItem label="批次名称" name="name"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.name" :maxlength="30" :showCount="true" placeholder="输入批次名称" />
                    </FormItem>

                    <FormItem label="备注" name="details" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.details" :maxlength="30" placeholder="输入批次备注" />
                    </FormItem>
                    <FormItem label="是否返厂" name="state" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <RadioGroup v-model:value="formState.state" :options="stateList" option-type="button"></RadioGroup>
                    </FormItem>
                    <FormItem label="生产时间" name="protime" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <DatePicker v-model:value="formState.protime" :allowClear="false" style="width: 100%;"></DatePicker>
                    </FormItem>
                    <!-- 导入SN -->
                    <div style="padding-bottom: -20px;">
                        <UploadDragger :showUploadList="false" @change="handleChange" :beforeUpload="beforeUpload"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            :disabled="disabled">
                            <p>
                                <InboxOutlined></InboxOutlined>
                            </p>
                            <p class="ant-upload-text">
                                单击或拖动文件到此区域进行上传</p>
                            <p class="ant-upload-hint">
                                严禁上传公司数据或其他文件
                            </p>
                        </UploadDragger>
                        <div style="margin-top: 20px; text-align: center;">
                            <TypographyTitle :level="4">
                                <span>一共导入</span>
                                <span style="color: red;">&nbsp;{{ sourceList.length }}&nbsp;</span>
                                <span>条设备信息</span>
                            </TypographyTitle>
                            <div style="position: relative; margin-top: -35px; height: 50px; width:500px;">
                                <FormItem name="data" :rules="[{ validator: validatePass, trigger: 'change', }]"
                                    style="margin: 0;">
                                </FormItem>
                            </div>
                            <div style="padding: 5px;">
                                <Button type="dashed" @click="open = true"
                                    :disabled="sourceList.length == 0"><a>点击查看SN信息</a></Button>
                            </div>
                        </div>
                    </div>

                    <FormItem :wrapperCol="{ offset: 0, span: 24 }">
                        <Button type="primary" html-type="submit" style="width:100%">确定</Button>
                        <Button type="primary" danger @click="close" style="width:100%; margin-top: 10px;">取消</Button>
                    </FormItem>
                    <Drawer v-model:open="open" placement="right">
                        <List :data-source="dataSource">
                            <template #renderItem="{ item }">
                                <Card>
                                    <Row :span="24">
                                        <Col :span="8">
                                        <span>设备SN:</span>
                                        </Col>
                                        <Col :span="16" style="font-weight: bolder;">
                                        {{ item.csn }}
                                        </Col>
                                    </Row>
                                    <Row :span="24">
                                        <Col :span="8">
                                        <span>医保SN:</span>
                                        </Col>
                                        <Col :span="16" style="font-weight: bolder;">
                                        {{ item.medicaresn }}
                                        </Col>
                                    </Row>
                                    <Row :span="24">
                                        <Col :span="8">
                                        <span>加密芯片:</span>
                                        </Col>
                                        <Col :span="16" style="font-weight: bolder;">
                                        {{ item.sesn }}
                                        </Col>
                                    </Row>
                                </Card>
                            </template>
                            <template #loadMore>
                                <div v-if="sourceList.length > dataSource.length"
                                    :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
                                    <Button @click="onLoadMore">loading more</Button>
                                </div>
                            </template>
                        </List>
                    </Drawer>
                </Form>
                <span style="text-align: right;">
                    <a href="/导入SN模板.xlsx" style="text-decoration: underline">
                        <div class="downdevicemobo">
                            <ArrowDownOutlined />
                            <span> 导入SN模板.xlsx </span>
                        </div>
                    </a>
                </span>
            </Card>
            <template #tip>
                <div v-if="spinIndex == 1">
                    <span style="color: red;">正在解析表格数据...</span>
                </div>
                <div style="width: 100%;  margin-top: -120px;" v-else>
                    <Progress :format="format" :stroke-color="{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }" type="circle" :percent="percent" :size="200"></Progress>
                </div>
            </template>
        </Spin>
    </Modal>
</template>

<script>
import { Modal, Upload, notification, Drawer, TypographyTitle, Button, Spin, Progress, Card, Row, Col, List, Form, Input, DatePicker, RadioGroup } from 'ant-design-vue'
import { reactive, toRefs, getCurrentInstance, ref } from 'vue'
import { InboxOutlined, ArrowDownOutlined } from "@ant-design/icons-vue";
import * as XLSX from 'xlsx';
import Que from "./que.js";
import dayjs from 'dayjs';

export default {
    name: "productionAdd",
    components: { Modal, UploadDragger: Upload.Dragger, InboxOutlined, Drawer, TypographyTitle, Button, Spin, Progress, Card, Row, Col, ArrowDownOutlined, List, Form, FormItem: Form.Item, Input, DatePicker, RadioGroup },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            notification,
            open: false,
            sourceList: [],
            spinning: false,
            percent: 0,
            disabled: false,
            successNum: 0,
            proxy,
            Que,
            XLSX,
            dayjs,
            spinIndex: 1,
            dataSource: [],
            offset: 1000,
            stateList: [{
                label: '返厂',
                value: 1,
            }, {
                label: '不返厂',
                value: 2,
            }]
        })
        const close = () => {
            if (state.spinning) {
                return;
            }
            emit("close");
        }
        const handleChange = () => {
            return false;
        }
        const beforeUpload = (file) => {
            if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                notification["error"]({
                    message: "导入失败",
                    description: "导入文件格式错误,只能导入Excel文件",
                });
                return false;
            }
            readExcel(file)
            return false;
        }
        const readExcel = (file) => {
            state.dataSource = [];
            state.sourceList = [];
            formState.data = [];
            state.disabled = true;
            state.spinning = true;
            state.spinIndex = 1;
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);
            fileReader.onload = (ev) => {
                try {
                    let snList = [];
                    const result = ev.target.result;
                    const workbook = XLSX.read(result, {
                        type: "binary",
                    });
                    const wsname = workbook.SheetNames[0];
                    const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
                    for (const val of ws) {
                        console.log(val);
                        if (!val["设备SN"]) {
                            notification["error"]({
                                message: "读取失败",
                                description: "设备SN不能为空",
                            });
                            state.disabled = false;
                            state.spinning = false;
                            state.spinIndex = 0;
                            return;
                        }
                        snList.push({
                            csn: val["设备SN"] + '',
                            sesn: val["加密芯片SN"] ? val["加密芯片SN"] + '' : '',
                            medicaresn: val["医保SN"] ? val["医保SN"] + '' : '',
                        })
                    }
                    state.disabled = false;
                    state.spinning = false;
                    state.spinIndex = 0;
                    if (snList.length == 0) {
                        notification["error"]({
                            message: "读取文件失败",
                            description: "未发现SN",
                        });
                        return;
                    }
                    state.open = true;
                    state.sourceList = snList;
                    formState.data = snList;
                    state.dataSource = snList.slice(0, 20);
                    formRef.value.validate(["data"]);
                    return;
                } catch (error) {
                    // 读取文件失败
                    notification["error"]({
                        message: "读取文件失败",
                        description: error,
                    });
                    return;
                }
            };
        }
        const format = () => {
            return state.successNum + "/" + state.sourceList.length;
        }
        const onLoadMore = () => {
            var snList = state.sourceList;
            const index = state.dataSource.length;
            state.dataSource = state.dataSource.concat(snList.slice(index, index + 20));
        }
        const formState = reactive({
            name: '',
            details: '',
            protime: dayjs().hour(0).minute(0).second(0),
            data: [],
            state: 1,
        });
        const formRef = ref(null)
        const onFinish = async (value) => {
            value.protime = value.protime.valueOf()
            state.spinning = true;
            var result = await Que.post(proxy, value)
            state.spinning = false;
            if (result) {
                close()
            }
        };

        const validatePass = (rule, value) => {
            switch (rule.field) {
                case "name":
                    if (value == "") {
                        return Promise.reject("批次名称不能为空")
                    }
                    break;
                case "data":
                    if (value.length == 0) {
                        return Promise.reject("设备不能为空")
                    }
                    break;
                case "protime":
                    console.log(value.format('YYYY-MM-DD HH:mm:ss'), value.valueOf());
                    break;
                default:
                    break;
            }
            return Promise.resolve()
        };
        return {
            ...toRefs(state), close, handleChange, beforeUpload, format, onLoadMore, formState, onFinish, validatePass, formRef
        }
    }
}
</script>

<style lang="scss" scoped></style>