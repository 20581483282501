<template>
    <Modal :centered="true" v-model:open="open" title="添加系统版本" :footer="null" @cancel="close" :maskClosable="false"
        :width="550">
        <Spin :spinning="spinning">
            <Card>
                <Form :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="on"
                    @finish="onFinish">
                    <FormItem label="系统版本" name="name"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.name" :maxlength="30" :showCount="true" placeholder="输入系统版本" />
                    </FormItem>
                    <FormItem label="解密附加" name="append"
                        :rules="[{ required: true, validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.append" :maxlength="30" :showCount="true" placeholder="输入解密附加信息" />
                    </FormItem>
                    <FormItem label="备注" name="details" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.details" :maxlength="30" placeholder="输入系统版本备注" />
                    </FormItem>
                    <FormItem :wrapperCol="{ offset: 0, span: 24 }">
                        <Button type="primary" html-type="submit" style="width:100%">确定</Button>
                        <Button type="primary" danger @click="close" style="width:100%; margin-top: 10px;">取消</Button>
                    </FormItem>
                </Form>
            </Card>
        </Spin>
    </Modal>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from 'vue'
import { Modal, Spin, Form, Input, Button, Card } from "ant-design-vue";
import Que from "./que.js";

export default {
    name: "sysversionAdd",
    components: { Modal, Spin, Form, FormItem: Form.Item, Input, Button, Card },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            open: true,
            spinning: false,
            Que,
        })
        const close = () => {
            emit("close")
        }
        const formState = reactive({
            name: '',
            details: "",
            append: "",
        });
        const validatePass = (rule, value) => {
            switch (rule.field) {
                case "name":
                    if (value == "") {
                        return Promise.reject("系统版本不能为空")
                    }
                    break;
                default:
                    break;
            }
            return Promise.resolve()
        };
        const onFinish = async (value) => {
            state.spinning = true;
            var result = await Que.post(proxy, value)
            state.spinning = false;
            if (result) {
                close()
            }
        };
        return {
            ...toRefs(state), close, onFinish, formState, validatePass
        }
    }
}
</script>

<style lang="scss" scoped></style>