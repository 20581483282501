import {notification} from 'ant-design-vue';

export default {
  login:
      async (proxy, paprm) => {
        const response = await proxy.$req.post('/v24000/login', paprm);
        if (response.code) {
          notification['error']({
            message: '登录失败',
            description: response.message,
          });
          return null;
        }
        return response;
      },
  code: async (proxy, paprm) => {
    const response = await proxy.$req.put('/v24000/login/reset/code', paprm);
    if (response.code) {
      notification['error']({
        message: '发送验证码失败',
        description: response.message,
      });
      return null;
    }
    notification['success']({
      message: '发送验证码成功',
      description: '发送验证码成功',
    });
    return response.data;
  },
  update: async (proxy, paprm) => {
    const response = await proxy.$req.put('/v24000/login/reset', paprm);
    if (response.code) {
      notification['error']({
        message: '修改失败',
        description: response.message,
      });
      return null;
    }
    notification['success']({
      message: '修改成功',
      description: '修改成功',
    });
    return true;
  },
};
