import {notification} from 'ant-design-vue';

export default {
  get:
      async (proxy, paprm) => {
        const response = await proxy.$req.get('/v24000/userWebchat', paprm);
        if (response.code != 0) {
          notification['error']({
            message: '获取数据失败',
            description: response.message,
          });
          return response;
        }
        return response;
      },
  put: async (proxy, paprm) => {
    const response = await proxy.$req.put('/v24000/userWebchat/user', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '绑定失败',
        description: response.message,
      });
      return response;
    }
    notification['success']({
      message: '绑定成功',
      description: response.message,
    });
    return response;
  },
};
