<template>
    <Modal :centered="true" v-model:open="open" title="生产批次修改" :footer="null" @cancel="close" :maskClosable="false"
        :width="550">
        <Spin :spinning="spinning">
            <Card>
                <Form :model="formState" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" autocomplete="on"
                    @finish="onFinish">
                    <FormItem label="备注" name="details" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <Input v-model:value="formState.details" :maxlength="30" placeholder="输入批次备注" />
                    </FormItem>
                    <FormItem label="是否返厂" name="state" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <RadioGroup v-model:value="formState.state" :options="stateList" option-type="button"></RadioGroup>
                    </FormItem>
                    <FormItem label="生产时间" name="protime" :rules="[{ validator: validatePass, trigger: 'change', }]">
                        <DatePicker v-model:value="formState.protime" :allowClear="false" style="width: 100%;"></DatePicker>
                    </FormItem>
                    <FormItem :wrapperCol="{ offset: 0, span: 24 }">
                        <Button type="primary" html-type="submit" style="width:100%">确定</Button>
                        <Button type="primary" danger @click="close" style="width:100%; margin-top: 10px;">取消</Button>
                    </FormItem>
                </Form>
            </Card>
        </Spin>
    </Modal>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { Modal, Spin, Form, Input, Button, Card, DatePicker, RadioGroup } from "ant-design-vue";
import Que from "./que.js";
import dayjs from 'dayjs';

export default {
    name: "productionUpdate",
    components: { Modal, Spin, Form, FormItem: Form.Item, Input, Button, Card, DatePicker, RadioGroup },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            open: true,
            spinning: false,
            Que,
            dayjs,
            stateList: [{
                label: '返厂',
                value: 1,
            }, {
                label: '不返厂',
                value: 2,
            }]
        })
        const close = () => {
            emit("close")
        }
        const formState = reactive({
            details: '',
            protime: dayjs().hour(0).minute(0).second(0),
            state: 1,
        });
        const validatePass = (rule, value) => {
            switch (rule.field) {
                case "protime":
                    console.log(value.format('YYYY-MM-DD HH:mm:ss'), value.valueOf());
                    break;
                default:
                    break;
            }
            return Promise.resolve()
        };
        const onFinish = async (value) => {
            value.protime = value.protime.valueOf()
            value.id = props.info.id;
            state.spinning = true;
            var result = await Que.put(proxy, value)
            state.spinning = false;
            if (result) {
                close()
            }
        };
        onMounted(() => {
            formState.details = props.info.details;
            formState.state = props.info.state;
            formState.protime = dayjs(parseInt(props.info.protime));
        })
        return {
            ...toRefs(state), close, onFinish, formState, validatePass
        }
    },
    props: {
        info: null,
    }
}
</script>

<style lang="scss" scoped></style>