import {notification} from 'ant-design-vue';

export default {
  get:
      async (proxy, paprm) => {
        const response = await proxy.$req.get('/v24000/sesn', paprm);
        if (response.code != 0) {
          notification['error']({
            message: '获取数据失败',
            description: response.message,
          });
          return response;
        }
        return response;
      },
  post: async (proxy, paprm) => {
    const response = await proxy.$req.post('/v24000/sesn', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '创建失败',
        description: response.message,
      });
      return null;
    } else {
      notification['success']({
        message: '创建成功',
        description: response.message,
      });
      return response;
    }
  },
  del: async (proxy, paprm) => {
    const response = await proxy.$req.del('/v24000/sesn', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '删除失败',
        description: response.message,
      });
      return response;
    } else {
      notification['success']({
        message: '删除成功',
        description: response.message,
      });
      return response;
    }
  },
};
